module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gdpr-cookies@2.0.9_gatsby@5.13.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@1_p7yiuosprjvssz2uenzvmnuc5i/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-P96JWR3W","cookieName":"ot-ga","routeChangeEvent":"gatsby-route-change"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel"},"tikTokPixel":{"pixelId":"","cookieName":"gatsby-gdpr-tiktok-pixel"},"hotjar":{"hjid":"","hjsv":"YOUR_HOTJAR_SNIPPET_VERSION","cookieName":"gatsby-gdpr-hotjar"},"linkedin":{"trackingId":"","cookieName":"gatsby-gdpr-linked-in"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-twitter@5.13.1_gatsby@5.13.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3._wgshsb5rpw5cbetcntzgzvvh4e/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-web-font-loader@1.0.4/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"fzm8exc"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__to5b6pz7ybeeqvoq5t2c366rn4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
